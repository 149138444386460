import * as React from "react";
import Lightbox from "yet-another-react-lightbox";
import '../assets/styles/gallery.css';
import "yet-another-react-lightbox/styles.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

const images_jpg_png = require.context("../assets/images/gallery", true, /\.(jpg|png)$/);
const images_webp = require.context("../assets/images/gallery", true, /\.(webp)$/);
const sortedImages = [];


// Obtener todas las claves (nombres de archivo) de las imágenes jpg/png
const jpgPngKeys = images_jpg_png.keys().sort((a, b) => {
  const numA = parseInt(a.match(/\d+/)[0]);
  const numB = parseInt(b.match(/\d+/)[0]);
  return numA - numB;
});

// Iterar sobre las claves y buscar su correspondiente webp
let i=0;
jpgPngKeys.forEach(jpgPngKey => {
  const baseName = jpgPngKey.replace(/\.(jpg|png)$/, ''); // get the name
  const webpKey = `${baseName}.webp`; // set the webp name
  const webpSrc = images_webp.keys().includes(webpKey) ? images_webp(webpKey) : null; //Check if it has a pair if not set null

  //enter into the sortedImages object
  sortedImages[i++] = {
    srcDefault: images_jpg_png(jpgPngKey),
    srcWebp: webpSrc
  };
});

function Gallery() {
    const [open, setOpen] = React.useState(false);
    const [activeIndex, setActiveIndex] = React.useState(-1);

    const toggleLightbox = (index) => {
      if(!open) setOpen(true);
      setActiveIndex(index);
    };

    
    return (  
      <section id="gallery" className='w-full py-8 lg:py-24'>
        <h2 className="w-full text-center text-4xl text-tertiary-300 pb-8">Gallery</h2>
        <div className="w-full flex flex-wrap container justify-center content-around gap-8">
          {sortedImages.map((image, index) => (
            <a className="gallery-image" href={image.default} onClick={() => toggleLightbox(index)}>
               <picture>
                <source srcSet={`${image.srcWebp}`} type="image/webp" />
                <source srcSet={`${image.srcDefault}`}/>
                <img className="h-auto max-h-[400px] max-w-auto" key={`${index}`} src={image} alt={`${index}-image`} lazyloading='lazy'/>
              </picture>
                
            </a>
          ))}
        </div>
  
        <Lightbox
          open={open}
          close={() => setOpen(false)}
          index={activeIndex}
          slides={sortedImages.map((image) => ({ src: image.srcDefault }))}
          plugins={[Zoom]}
          zoom={{
            scrollToZoom:true,
            maxZoomPixelRatio:1
          }}
        />
      </section>
    );
}

export default Gallery;
