const navDirs = [
  { name: "About me", href: "#about-me" },
  { name: "Experience", href: "#experience" },
  { name: "Gallery", href: "#gallery" },
];

const Navlinks = () =>
  navDirs.map((navItem) => (
    <li className="text-base nav-a" key={navItem.name} >
      <a href={navItem.href}>{navItem.name}</a>
    </li>
  ));

export default Navlinks;