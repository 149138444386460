import "../assets/styles/about.css";

function About({ title, about }) {
  return (
    <section id="about-me" className="w-full py-10 lg:py-16">
      <div className="w-full lg:w-8/12 flex flex-wrap container justify-center content-center">
      <h2 className="w-full text-center text-4xl text-tertiary-300 pb-8">About me</h2>
        <p className="text-sm lg:text-base tracking-widest text-center lg:text-start neu p-10">
          With 5 years of experience, I am an experienced <span className="text-tertiary-300">{title}</span>. My skills
          cover HTML, PHP, CSS, JavaScript, MySQL, WordPress, ACF, Jet Engine,
          various frameworks such as jQuery, Tailwind CSS, UIKit and Bootstrap
          as well as the use of visual builders such as Elementor, Webflow and more. My
          versatility within web development in both frontend and backend allows
          me to offer comprehensive solutions with top-notch SEO performance. In
          addition to being able to speak native Spanish, I have a C2 of English according to <a href="https://cert.efset.org/RZcCJJ" className="text-tertiary-300">EFSET</a>,
          basic knowledge of Italian.
        </p>
      </div>
    </section>
  );
}

export default About;
