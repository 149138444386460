import "./assets/styles/App.css";
import Header from "./components/header";
import Hero from "./components/hero";
import About from "./components/aboutme";
import Gallery from "./components/gallery";
import Experiences from "./components/experience";
import { SpeedInsights } from "@vercel/speed-insights/react"


const name = 'Stefano Strippoli';
const title = 'WordPress Developer';
/*
import Cursor from 'custom-cursor'
document.addEventListener('DOMContentLoaded', () => {
  new Cursor({
    targets: ['a', '.gallery-image'],
  })
})
*/
function App() {
  return (
    <>
      <Header name={name} />
      <Hero name={name} title={title}/>
      <About title={title}/>
      <Gallery/>
      <SpeedInsights/>
    </>
  );
}

export default App;
