import '../assets/styles/hero.css';
const heroimgContext = require.context('../assets/images/hero', true, /\.(jpg|png|webp)$/);

const imageKeys = heroimgContext.keys().sort();
const lastImageKey = imageKeys[imageKeys.length - 1];
const imageName = lastImageKey.match(/^(.*)\.(jpg|png|webp)$/)[1];
const heroimgSet = {
  srcDefault: heroimgContext(`${imageName}.jpg`),
  srcWebp: heroimgContext(`${imageName}.webp`)
};

console.log(heroimgSet);


function Hero({ name, title}) {
  return (
    <section id='hero' className="w-full py-20 lg:py-24">
      <div className='h-full w-full lg:w-8/12 flex flex-wrap container justify-center content-center'>
        <div id='hero-img' className='w-full xl:w-5/12'>
        <picture>
          <source srcSet={`${heroimgSet.srcWebp}`} type="image/webp"></source>
          <source srcSet={`${heroimgSet.srcDefault}`}></source>
          <img src={`${heroimgSet.srcDefault}`} alt={`image-hero`} lazyloading='lazy'></img>
        </picture>
        </div>
        <div className='w-full xl:w-7/12 text-center text-2xl lg:text-3xl xl:text-5xl flex flex-wrap content-center justify-center py-12 xl:py-0 lg:px-8'><h1>Hi, I'm <a href="#about-me"><span id='hero-name'>{name}</span></a>.<br></br> I'm a <a href="#experience"><span id='hero-title'>{title}</span></a>.</h1></div>
      </div>
    </section>
  );
}
export default Hero;