import '../assets/styles/header.css';
import Navlinks from './navlinks';
import { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';   


function Header({ name }) {
  const [isToggled, setIsToggled] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [isfirstUse, setIsFirstUse] = useState(true);
  const toggleMenu = () => {
    setIsFirstUse(false);
    if (isWaiting) return;
    if (!isWaiting) {
      setIsToggled((open) => !open);
      setIsWaiting(true);
      setTimeout(() => {
        setIsWaiting(false);
      }, 700);
    }
  };

  return (
    <div className="w-full App-header">
      <header className={`w-full ${isToggled ? "bg-neutral-900 shadow-lg" : "bg-[#16171a]"} stuck flex flex-wrap`}>
        <div id='navbar' className={`relative nav flex flex-wrap w-full text-neutral-100 container ${isToggled ? "bg-neutral-900" : "bg-[#16171a] lg:max-h-auto max-h-16"}`}>
          <div className='w-2/3 md:w-1/3 flex flex-wrap content-center text-lg lg:text-2xl min-h-16'><a href='#' className='text-tertiary-300'><h1>{name}</h1></a></div>
          <div className='w-1/3 lg:w-2/3 flex flex-wrap justify-end gap-6 content-center md:hidden'>
            <span id='mbmenu' className={`${isToggled ? "toggledOn" : ""}`} onClick={toggleMenu}>{isToggled ? <CloseIcon/> : <MenuIcon/>}</span>
          </div>
          <ul id="navlinks" className={`w-full md:w-2/3 lg:w-2/3 flex flex-col md:flex-row flex-wrap md:justify-end gap-6 content-start lg:content-center py-8 lg:py-0 lg:px-10 ${isfirstUse ? '' : ''} ${isToggled ? "slideInLeft" : "slideOutLeft"}`}><Navlinks/></ul>
        </div>
      </header>
    </div>
  );
}

export default Header;